/* eslint-disable no-console */

import { getFieldHandler } from '@/hosting/keap-forms-processing/field-types';
import { isEmptyValue } from '@/hosting/keap-hosting-util';
import { getItem, setItem } from '@/hosting/storage';
import { parseUrlQueryString } from '@/shared/querystring-utils';

/**
 * A class applied to fields when they are in an invalid state.
 * @type {string}
 */
const ERROR_FIELD_CLASS = 'error';

export const KEAP_FORM_STATE_KEY = 'keap-hosting-form-state';

/**
 * Class that manages the state of all registered form fields, receives and propagates change updates from individual form controls.
 *
 * @property {Object.<string, KeapFieldState>} fields All registered form fields for this form.  These form fields are
 */
export class KeapFormState {
    /**
     * @param onFieldValueChanged A callback to be invoked when the value of an individual form control changes
     */
    constructor({ onFieldValueChanged }) {
        if (!onFieldValueChanged) {
            throw Error('Missing onFieldValueChanged');
        }
        this.onFieldValueChanged = onFieldValueChanged;
        this.fields = {};
    }

    registerField(fieldState) {
        this.fields[fieldState.name] = fieldState;
    }

    /**
     * Notifies that a single form value has been updated
     *
     * @param {FormFieldUpdateEvent} updateEvent
     */
    updated(updateEvent) {
        this.onFieldValueChanged(updateEvent);
    }

    /**
     * Returns a copy of all form field values.
     * @return {FormFieldSnapshot}
     */
    get currentValues() {
        const fields = Object.entries(this.fields)
            .reduce((snap, [key, state]) => {
                snap[key] = state.convertedValue;

                return snap;
            }, {});

        return { fields };
    }

    /**
     * Initializes the form by prefilling from local storage, and then filling from request parameters
     */
    initFormValues() {
        try {
            const formState = getItem(KEAP_FORM_STATE_KEY) ?? {};

            Object.entries(formState).forEach(([fieldKey, value]) => {
                const field = this.fields[fieldKey];

                if (field && value && fieldKey !== 'standard.tag') {
                    field.bind(value);
                }
            });

            // Use any request parameters as overrides
            const queryString = parseUrlQueryString(window.location.href);

            Object.entries(this.fields).forEach(([fieldName, fieldState]) => {
                const fromQuery = queryString[fieldName];

                if (fromQuery) {
                    const _converted = fieldState.update(fromQuery, false);

                    fieldState.bind(_converted);
                }
            });
        } catch (e) {
            console.warn('Error pre-filling fields', e);
        }
    }

    /**
     * Saves the form state to local storage
     */
    persist() {
        const { fields } = this.currentValues;

        setItem(KEAP_FORM_STATE_KEY, fields);
    }
}

/**
 * Maintains the state of an individual form field.  Will listen to and propagate any changes up to the connected form.
 *
 * @property {HTMLElement} self The primary HTML element backing this form field (there may be many html inputs that represent a single field, like a list of checkboxes)
 * @property {function} onBind Callback function used to bind data to the form
 * @property {string} inputType The type of form control
 * @property {string} fieldType The type of data
 * @property {string} name The name of this field
 * @property {string} fieldLabel The label for this field
 * @property {?Object} options Any extra options for this field, such as the possible list of values to pick from, in the case of a drop-down
 * @property {Function} onUpdated A callback to be invoked when the value of this field changes.  Generally, this will pass the values up to the form state
 * @property {Date} modified When the field was last modified
 * @property {*} value The current value of this field
 * @property {boolean} isRequired Whether this form field is required
 */
export class KeapFieldState {

    constructor(ctr) {
        const { onBind, isRequired, inputType, fieldType, fieldName, fieldLabel, options, formState, self } = ctr;

        this.isRequired = isRequired !== false;
        this.inputType = inputType;
        this.self = self;
        this.options = options;
        this.fieldType = fieldType;
        this.name = fieldName;
        this.errors = [];

        // Raw value typed into a text box
        this._value = null;

        this.onBind = onBind;
        // The converted value, a number, boolean, date, etc.
        this._convertedValue = null;
        this.fieldLabel = fieldLabel;
        this.handler = getFieldHandler(this.fieldType);
        this.onUpdated = ((oldValue, newValue) =>
            formState.updated({
                name: fieldName,
                oldValue, newValue,
                formState,
            }));
        formState.registerField(this);
    }

    get value() {
        return this._value;
    }

    get convertedValue() {
        return this._convertedValue;
    }

    /**
     * Converts the data into the appropriate type, and performs validation.  This will also check whether this field
     * is required or not.
     *
     * @param {boolean} propagate Whether the results of this operation should be applied directly to the form.  This will
     * cause the form's valid/invalid state to update
     * @return {ValidationResult}
     */
    convertAndValidate({ propagate = true } = {}) {
        if (this.isRequired && isEmptyValue(this._value)) {
            this.errors = ['required'];
            this._convertedValue = null;
        } else if (!isEmptyValue(this._value)) {
            const { value, errors = [] } = this.handler.convertAndValidate(this._value);

            this._convertedValue = value;
            this.errors = errors;
        }

        if (propagate) this.updateErrorState();

        if (!this.isRequired && isEmptyValue(this._value)) {
            return {};
        }

        return { value: this._convertedValue, errors: this.errors };
    }

    updateErrorState() {
        const parent = this.self.closest('.input-field') ?? this.self;

        if (this.errors?.length > 0) {
            parent.classList.add(ERROR_FIELD_CLASS);
        } else {
            parent.classList.remove(ERROR_FIELD_CLASS);
        }
    }

    get modified() {
        return this._modified;
    }

    /**
     * Binds an external value to this form control.  This should update the UI.
     * @param newValue
     */
    bind(newValue) {
        this.onBind(newValue);
        this._convertedValue = newValue;
        this._value = newValue;
    }

    /**
     * Updates the value for this form field, and propagates the change if a) the value has changed, and b) the parameter `propagate` is true
     *
     * @param newValue The new value for this field
     * @param {boolean} propagate Whether to propagate the change upwards
     */
    update(newValue, propagate = true) {
        const oldValue = this._convertedValue ?? this._value;

        this._value = newValue;

        const { value:convertedValue } = this.convertAndValidate({ propagate });

        if (oldValue !== convertedValue && propagate) {
            this._modified = true;
            this.onUpdated(oldValue, convertedValue);
        }

        return this._convertedValue;
    }
}

/**
 * @typedef FormFieldUpdateEvent
 *
 * @property {String} name The name of the field being updated
 * @property {Object} oldValue The previous value being updated
 * @property {Object} newValue The new value for this field
 * @property {KeapFormState} formState The formState this is being applied to
 */

/**
 * @typedef FormFieldSnapshot
 *
 * @property {Object.<string, *>} fields An object literal containing the value of each fields, using the key as the name.  If the field has not been set, the value will be `undefined`
 */
